#block-block-1 {
    margin-top: $padding-large-vertical * 5;
    .block-title {
        @extend .underline-header;

        .context-midshire & {
            &:after {
                background-color: $midshire-green;
            }
        }
    }
}