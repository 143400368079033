.image-block {
    margin-bottom: $padding-large-vertical * 3;

    .content {
        height: 100%;
    }

    .col-sm-12 {
        &:first-child {
            padding-right: 0;
        }

        > h3 {
            margin-top: 0;
            font-size: $font-size-h4;
            position: relative;
            padding-bottom: $padding-large-vertical;
            color: $paws-dark-green;
            font-weight: 600;

            &:after {
                content: "";
                width: 40px;
                left: 0;
                height: 2px;
                background-color: $paws-red;
                position: absolute;
                bottom: 0;

                .context-midshire & {
                    background-color: $midshire-green;
                }
            }
        }
    }

    .field-name-field-body {
        font-size: $font-size-base * 0.85;
        margin-bottom: $padding-large-vertical;
    }

    .field-name-field-image {
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .field-name-field-link {
        margin-top: $padding-large-vertical;
    }
}

@media (min-width: $screen-sm-min) {
    .image-block {
        .col-sm-12 {
            &:first-child {
                padding-right: round($grid-gutter-width / 2);
            }

            > h3 {
                padding-top: $padding-large-vertical * 2;
                font-size: $font-size-h4 * 1.1;
                text-align: center;

                &:after {
                    margin-left: -20px;
                    left: 50%;
                }
            }
        }

        .field-name-field-body {
            font-size: $font-size-base;
            text-align: center;
        }

        .field-name-field-link {
            text-align: center;
        }
    }
}