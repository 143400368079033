$width: 808px;
$height: 808px;
$ratio: 0.07;

.footer {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: $footer-height-sm;
    background: url('../images/backgrounds/footer-generic.jpg') 50% 50% no-repeat;
    background-size: cover;
    color: $white;
    font-size: $font-size-base * 0.8;
    font-weight: 100;
    text-align: center;

    a {
        color: $white;
    }

    .inner {
        padding: 0 30px;
    }

    .xlvets-logo {
        width: round($width * $ratio);
        height: round($height * $ratio);
        display: inline-block;
        background: url('../images/logos/thriving-logo-white.svg') 50% 50% no-repeat;
        background-size: 100% 100%;
        margin-right: 8px;
    }

    .rcvs-logo {
        $width: 250px;
        $height: 176px;
        $ratio: 0.3;

        width: round($width * $ratio);
        height: round($height * $ratio);
        display: inline-block;
        background: url('../images/logos/rcvs.png') 50% 50% no-repeat;
        background-size: 100% 100%;
        margin-left: 8px;
    }

    .logos {
        margin: 0 auto;
    }

    .company-address {
        clear: both;
        padding-top: $padding-large-vertical;
    }

    .copyright {
        padding-top: $padding-large-vertical;
    }

    .author {
        a:hover {
            text-decoration: none;
        }

        span {
            position: relative;
            padding-bottom: 3px;

            &:after {
                transition: width 400ms;
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                height: 1px;
                background-color: $white;
                width: 0;
            }
        }

        a:hover {
            span {
                &:after {
                    width: 33px;
                }
            }
        }
    }

    .context-paws & {
        background-image: url('../images/backgrounds/footer-paws.jpg');
    }
}


@media (min-width: $screen-sm-min) {
    .footer {
        text-align: left;
        height: $footer-height;

        .copyright {
            padding-top: round($height * $ratio) + $padding-large-vertical;
        }

        .logos {
            text-align: right;
        }

        .company-address {
            text-align: right;
        }
    }
}
