h1, .h1 { font-size: $font-size-h1 * 0.8; }
h2, .h2 { font-size: $font-size-h2 * 0.8; }
h3, .h3 { font-size: $font-size-h3 * 0.8; }
h4, .h4 { font-size: $font-size-h4 * 0.8; }
h5, .h5 { font-size: $font-size-h5 * 0.8; }
h6, .h6 { font-size: $font-size-h6 * 0.8; }

h1,h2,
.h1,.h2{
    text-transform: uppercase;
}

@media (min-width: $screen-sm-min) {
    h1, .h1 { font-size: $font-size-h1; }
    h2, .h2 { font-size: $font-size-h2; }
    h3, .h3 { font-size: $font-size-h3; }
    h4, .h4 { font-size: $font-size-h4; }
    h5, .h5 { font-size: $font-size-h5; }
    h6, .h6 { font-size: $font-size-h6; }
}