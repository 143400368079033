.navbar-inverse {
    min-height: $navbar-inverse-height;

    .navbar-nav {
        > li > a {
            @media(min-width: $grid-float-breakpoint) {
                $padding-vertical: (($navbar-inverse-height - $line-height-computed) / 2);
                padding-top: $padding-vertical;
                padding-bottom: $padding-vertical;
            }
        }
    }
}