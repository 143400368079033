.text-split {
    padding: ($padding-large-vertical * 5) 0;

    .field-name-field-title {
        font-size: $font-size-h3;
        font-weight: 600;
        text-align: center;
        margin-top: 0;
        margin-bottom: $padding-large-vertical * 4;
        padding-bottom: $padding-large-vertical * 2;
        position: relative;
        line-height: $font-size-h3 * 1.1;

        &:after {
            content: "";
            width: 60px;
            left: 50%;
            margin-left: -30px;
            height: 2px;
            background-color: $white;
            position: absolute;
            bottom: 0;
        }
    }

    .field-name-field-body {
        text-align: center;
    }
}

@media(min-width: $screen-sm-min) {
    .text-split {
        .field-name-field-title {
            text-align: right;
            padding-right: $padding-large-vertical;

            &:after {
                left: auto;
                right: $padding-large-vertical;
                margin-left: 0;
            }
        }

        .field-name-field-body {
            text-align: left;
        }
    }
}