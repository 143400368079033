.just-text {
    h1 {
        @extend .underline-header;
        font-size: $font-size-h2 * 0.7;
    }
}

@media (min-width: $screen-sm-min) {
    .just-text {
        h1 {
            font-size: $font-size-h2;
        }
    }
}