.region-top-contact {
    .block {
        margin-top: $padding-large-vertical;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-base * 1.1;

        strong {
            font-size: $font-size-base * 1.4;
        }
    }

    .block-title {
        display: none;
        text-transform: none;
        color: $paws-red;

        .context-midshire & {
            color: $midshire-green;
        }
    }
}

.return-link {
    text-align: center;

    a {
        display: block;
        padding: $padding-base-vertical $padding-base-horizontal;
        font-size: round($font-size-base * 0.9);
    }
}

.domain-www-midshirevetgroup-co-uk {
    .contact-banner,
    #navbar {
        display: none;
    }
}

@media (min-width: $screen-md-min) {
    .region-top-contact {
        .block {
            margin-top: $padding-large-vertical * 2;

            text-align: right;

            strong {
                display: block;
            }
        }

        .block-title {
            display: block;
            font-size: $font-size-h3;
        }
    }

    .return-link {
        text-align: left;
    }
}

@media (min-width: $screen-lg-min) {
    .region-top-contact {
        .block {
            strong {
                display: inline-block;
            }
        }

        .block-title {
            font-size: $font-size-h2;
        }
    }
}