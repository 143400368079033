.jumbotron {
    text-align: center;

    h1 {
        @extend .underline-header;
        font-size: $font-size-h2 * 0.7;
    }

    .field-name-field-body {
        font-family: $font-family-serif;
        font-style: italic;
        margin-bottom: $padding-large-vertical * 2;
        letter-spacing: 0.07em;
    }

    .context-paws & {
        .field-name-field-body {
            color: $gray;
        }
    }

    .context-midshire & {
        h1 {
            &:after {
                background-color: $midshire-green;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .jumbotron {
        h1 {
            font-size: $font-size-h2;
        }

        .field-name-field-body {
            max-width: 80%;
            margin: 0 auto;
        }
    }
}