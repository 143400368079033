.logo {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    display: block;
    margin-top: $padding-large-vertical * 2;
    cursor: pointer;
    max-width: 100%;
    margin: ($padding-large-vertical * 1) auto 0 auto;

    .context-midshire & {
        $width: 400px;
        $height: 78px;
        $ratio: 0.9;

        width: round($width * $ratio);
        height: round($height * $ratio);
        background-image: url('../images/logos/midshire.png');
    }

    .context-paws & {
        $width: 600px;
        $height: 241px;
        $ratio: 0.4;

        width: round($width * $ratio);
        height: round($height * $ratio);
        background-image: url('../images/logos/paws.png');
    }

    .context-whitestone & {
        $width: 600px;
        $height: 241px;
        $ratio: 0.4;

        width: round($width * $ratio);
        height: round($height * $ratio);
        background-image: url('../images/logos/whitestone.png');
    }
}

.alt-logo {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    display: block;
    margin-top: $padding-large-vertical * 2;
    cursor: pointer;
    max-width: 100%;
    margin: ($padding-large-vertical * 1) auto 0 auto;
}

@media (min-width: $screen-sm-min) {
    .logo {
        margin-top: $padding-large-vertical * 3;
    }

    .alt-logo {
        margin-top: $padding-large-vertical * 5;
    }
}

@media (min-width: $screen-md-min) {
    .logo,
    .alt-logo {
        margin-left: 0;
    }
}