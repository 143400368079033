.top-banner {
  background-color: $gray;
  color: $white;
  padding: 10px;
  font-size: round($font-size-base * 0.9);
  font-weight: 100;

  ul {
    margin: 0;
    padding: 0;
    text-align: center;

    li {
      list-style: none;
      margin-bottom: 5px;

      &:last-child {
        display: none;
      }
    }
  }

  a {
    color: $white;
  }
}

@media (min-width: $screen-sm-min) {
  .top-banner {
    ul {
      text-align: left;

      li {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 20px;

        &:last-child {
          display: inline-block;
          margin-right: 0;
        }
      }
    }
  }
}
