.text-with-image {
    margin-bottom: 20px;

    h1 {
        @extend .underline-header;
        font-size: $font-size-h2 * 0.7;
    }

    .field-name-field-image {
        img {
            @extend .img-thumbnail;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .text-with-image {
        h1 {
            font-size: $font-size-h2;
        }
    }
}