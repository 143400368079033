.three-columns {
    margin-top: $padding-large-vertical * 5;

    .content {
        > h1 {
            text-align: center;
            font-size: $font-size-h2 * 0.8;
            position: relative;
            padding-bottom: $padding-large-vertical;
            margin-bottom: $padding-large-vertical * 3;

            &:after {
                content: "";
                width: 100px;
                left: 50%;
                margin-left: -50px;
                height: 2px;
                background-color: $paws-red;
                position: absolute;
                bottom: 0;
            }
        }
    }

    .context-midshire & {
        .content {
            > h1 {
                &:after {
                    background-color: $midshire-green;
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .three-columns {
        .content {
            > h1 {
                font-size: $font-size-h2;

                &:after {
                    bottom: auto;
                    top: round($font-size-h2 / 2);
                    width: 500px;
                    margin-left: -250px;
                    z-index: -1;
                }

                span {
                    background-color: $white;
                    display: inline-block;
                    padding: 0 $padding-base-horizontal;
                }
            }
        }
    }
}