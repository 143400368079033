.underline-header {
    position: relative;
    padding-bottom: $padding-large-vertical * 3;
    text-transform: uppercase;
    margin-bottom: $padding-large-vertical * 3;
    text-align: center;

    &:after {
        $width: 150px;
        content: "";
        position: absolute;
        width: $width;
        bottom: 0;
        left: 50%;
        margin-left: round($width / -2);
        background-color: $lime-green;
        height: 2px;
    }

    .context-paws &,
    .context-whitestone & {
        &:after {
            background-color: $paws-red;
        }
    }

    .context-midshire & {
        &:after {
            background-color: $midshire-green;
        }
    }
}

.page-header {
    @extend .underline-header;
    border-bottom: 0 none;
    font-size: $font-size-h2 * 0.7;

    .node-type-fancy-page & {
        display: none;
    }

    @media (min-width: $screen-md-min) {
        font-size: $font-size-h2;
    }
}