.carousel-title,
.carousel-link {
    @extend .carousel-caption;
}

.slideshow {
    .item {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        min-height: 200px;
        font-family: $font-family-roboto;

        .carousel-caption {
            bottom: auto;
            top: 65px;
        }

        .carousel-title {
            top: 30px;
            bottom: auto;
        }
    }

    .context-generic & {
        .item {
            .carousel-caption {
                text-transform: uppercase;
                font-size: $font-size-base * 0.7;
            }

            .carousel-title {
                background-color: rgba(0, 0, 0, 0.4);
                border: 1px solid $white;
                padding: 20px 10px 45px 10px;
                text-transform: uppercase;
                font-size: $font-size-base;
                left: 50%;
                right: auto;
                width: 260px;
                margin-left: -130px;
            }
        }
    }

    .context-paws &,
    .context-midshire & {
        .item {
            min-height: 240px;

            .carousel-caption-custom {
                left: 0;
                right: 40%;
                background-color: $paws-red;
                background-color: rgba($paws-red, 0.6);
                bottom: 0;
                top: 0;
                color: #fff;
                z-index: 0;
                text-shadow: none;
                text-align: left;

                &:after {
                    $size: 50px;
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: $size * -1;
                    bottom: 0;
                    width: 0;
                    border-style: solid;
                    border-width: 500px 0 0 $size;
                    border-color: transparent transparent transparent $paws-red;
                    border-color: transparent transparent transparent rgba($paws-red, 0.6);
                }

                .field-item {
                    padding: $padding-base-horizontal 0 $padding-base-horizontal $grid-gutter-width;
                    font-size: $font-size-base * 0.87;
                    position: absolute;
                    bottom: 60px;
                }
            }

            .carousel-link {
                position: absolute;
                bottom: 0;
                top: auto;
                left: $grid-gutter-width;
                right: auto;
                a {
                    @extend .btn;
                    @extend .btn-xs;
                    @extend .btn-default;
                }
            }
        }
    }

    .context-midshire & {
        .item {
            .carousel-caption-custom {
                background-color: $midshire-dark-green;
                background-color: rgba($midshire-dark-green, 0.5);

                &:after {
                    border-color: transparent transparent transparent $midshire-dark-green;
                    border-color: transparent transparent transparent rgba($midshire-dark-green, 0.5);
                }
            }
        }
    }
}

@media (min-width: $screen-xs-min) {
    .slideshow {
        .item {
            min-height: 250px;
        }
    }

    .context-generic {
        .slideshow {
            .item {
                .carousel-caption {
                    font-size: $font-size-base * 0.8;
                    top: 95px;
                }

                .carousel-title {
                    font-size: $font-size-base * 1.3;
                    top: 50px;
                    padding-bottom: 55px;
                    width: 400px;
                    margin-left: -200px;
                }
            }
        }
    }

    .context-paws,
    .context-midshire {
        .slideshow {
            .item {
                min-height: 250px;

                .carousel-caption-custom {
                    right: 60%;

                    .field-item {
                        font-size: $font-size-base * 0.9;
                    }
                }

                .carousel-link {
                    a {
                        @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .slideshow {
        .item {
            min-height: 300px;
        }
    }

    .context-generic {
        .slideshow {
            .item {
                .carousel-caption {
                    font-size: $font-size-base;
                    top: 145px;
                }

                .carousel-title {
                    font-size: $font-size-base * 1.6;
                    top: 90px;
                    padding-bottom: 45px;
                    width: 450px;
                    margin-left: -225px;
                }
            }
        }
    }

    .context-paws,
    .context-midshire {
        .slideshow {
            .item {
                min-height: 300px;

                .carousel-caption-custom {
                    right: 70%;

                    .field-item {
                        font-size: $font-size-base * 1.1;
                        bottom: 80px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .slideshow {
        .item {
            min-height: 400px;
        }
    }

    .context-generic {
        .slideshow {
            .item {
                .carousel-caption {
                    font-size: $font-size-base * 1.3;
                    top: 180px;
                }

                .carousel-title {
                    font-size: $font-size-base * 2.2;
                    top: 130px;
                    width: 560px;
                    margin-left: -280px;
                }
            }
        }
    }

    .context-paws,
    .context-midshire {
        .slideshow {
            .item {
                min-height: 400px;

                .carousel-caption-custom {
                    right: 75%;

                    .field-item {
                        font-size: $font-size-base * 1.2;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .slideshow {
        .item {
            min-height: 600px;
        }
    }

    .context-generic {
        .slideshow {
            .item {
                .carousel-caption {
                    font-size: $font-size-base * 1.5;
                    top: 280px;
                }

                .carousel-title {
                    font-size: $font-size-base * 2.6;
                    top: 220px;
                    width: 700px;
                    margin-left: -350px;
                    padding-bottom: 55px;
                }
            }
        }
    }

    .context-paws,
    .context-midshire {
        .slideshow {
            .item {
                min-height: 500px;

                .carousel-caption-custom {
                    .field-item {
                        font-size: $font-size-base * 1.6;
                        bottom: 100px;
                    }
                }
            }
        }
    }
}