.paragraphs-item-practice-blocks {
    @extend .row;
}

.practice-block {
    background-color: $gray-lighter;
    position: relative;
    margin-bottom: $padding-large-vertical;

    .field-name-field-logo {
        padding: 20px;
        position: relative;

        &:after {
            $size: 12px;
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: $size $size 0 $size;
            border-color: $gray-lighter transparent transparent transparent;
            z-index: 100;
            position: absolute;
            bottom: $size * -1;
            left: 20px;
        }

        img {
            margin: 0 auto;
            width: auto;
            height: 60px;
        }
    }

    .field-name-field-image {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #000;
            opacity: 0.3;

        }
    }

    .field-name-field-body,
    .links {
        padding: 0 30px;
        font-size: $font-size-base * 0.9;
        line-height: $font-size-base * 1.4;
        color: $gray;

        .phone {
            margin-bottom: 0;
        }

        .website {
            font-family: $font-family-serif;
            font-style: italic;

            a {
                color: $gray-light;
            }
        }
    }

    .field-name-field-body {
        padding-top: $padding-large-vertical * 3;
    }

    .links {
        padding-top: $padding-large-vertical * 2;
        padding-bottom: $padding-large-vertical * 3;
    }

    .social {
        position: absolute;
        bottom: 10px;
        right: 30px;

        li {
            list-style: none;
            display: inline-block;
            margin-right: $padding-xs-horizontal;

            &:last-child {
                margin-right: 0;
            }

            a {
                $size: 25px;
                display: inline-block;
                width: $size;
                height: $size;
                background-color: lighten($gray-light, 10%);
                color: $gray-lighter;
                border-radius: $size;
                text-align: center;

                span {
                    font-size: $font-size-base * 1.2;
                    line-height: $font-size-base * 1.8;
                }
            }
        }
    }
}