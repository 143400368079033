html {
    height: 100%;
    padding: 10px;
}

.main-container {
  overflow: hidden;
}

body {
    position: relative;
    min-height: 100%;
    padding-bottom: $footer-height-sm;
    border: 1px solid #000;

    &.admin-menu {
        &.adminimal-menu {
            margin-top: 20px !important;
        }
    }
}

a {
    .context-midshire &,
    .context-generic & {
        color: $midshire-green;

        &.btn-info,
        &.btn-primary,
        &.btn-success,
        &.btn-warning,
        &.btn-danger {
            color: $white;
        }
    }

    .context-paws &,
    .context-whitestone {
        color: $paws-red;

        &.btn-info,
        &.btn-primary,
        &.btn-success,
        &.btn-warning,
        &.btn-danger {
            color: $white;
        }
    }
}

@media (min-width: $screen-sm-min) {
    body {
        padding-bottom: $footer-height;
    }
}
