
.navbar-header {
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    margin: 0 ($grid-gutter-width / 2);
}

.navigate-label {
    float: left;
    line-height: $navbar-height;
    padding-right: $padding-base-horizontal;
    color: $gray-light;
}

.navbar-toggle {
    margin-right: 0;
}

.nav {
    border-bottom: 1px solid $gray-lighter;
    padding-bottom: $padding-large-vertical;
    padding-top: $padding-large-vertical;

    > li {
        text-transform: uppercase;

        a {
            color: $gray;

            &:hover {
                background-color: transparent;
                color: $paws-red;

                .context-generic &,
                .context-midshire & {
                    color: $midshire-green;
                }
            }
        }

        &.active-trail a,
        a.active {
            color: $paws-red;

            .context-midshire &,
            .context-generic & {
                color: $midshire-green;
            }

            &:hover {
                color: $paws-red;

                .context-generic &,
                .context-midshire & {
                    color: $midshire-green;
                }
            }
        }
    }
}

@media (min-width: $grid-float-breakpoint) {
    #navbar {
        margin: ($padding-large-vertical * 3) 0;
    }

    .navigate-label {
        display: none;
    }

    .nav {
        text-align: right;
        border: 0 none;
        padding-bottom: 0;

        > li {
            display: inline-block;
            font-size: $font-size-base * 1.2;
        }
    }
}

.region-legal-menu {
    .nav {
        padding: 0;
        margin-bottom: $padding-large-vertical;

        > li {
            padding: 0;
            margin: 0 $padding-base-horizontal;

            &:last-child {
                margin-right: 0;

                > a {
                    padding-right: 0;
                }
            }

            > a {
                text-transform: none;
                font-size: $font-size-base * 0.8;
                padding: 4px;

                &:link,
                &:visited,
                &:hover,
                &:active {
                    color: #fff;
                    background-color: transparent;
                }

                &:hover,
                &:focus {
                    text-decoration: underline;
                    color: #fff;
                }

                &.active {
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
}