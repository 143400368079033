.view-how-to-videos {
    .view-content {
        @extend .row;
    }

    .views-row {
        @extend .col-xs-12;
        @extend .col-sm-6;
        @extend .col-md-4;
        @extend .col-lg-3;
        @extend .text-center;

        margin-bottom: 40px;
    }

    .views-field-title {
        font-size: $font-size-h4;
    }

    iframe {
        width: 100%;
        min-height: 250px;
        max-width: 100%;
    }
}

@media (min-width: $screen-sm-min) {
    .view-how-to-videos {
        .views-row {
            &:nth-child(2n + 1) {
                clear: both;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .view-how-to-videos {
        .views-row {
            &:nth-child(2n + 1) {
                clear: none;
            }

            &:nth-child(3n + 1) {
                clear: both;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .view-how-to-videos {
        .views-row {
            &:nth-child(3n + 1) {
                clear: none;
            }

            &:nth-child(4n + 1) {
                clear: both;
            }
        }
    }
}